body {
    direction: rtl;
    text-align: right;
}

.list-unstyled {
    padding-left: initial !important;
    padding-right: 0;
}

/* ----------------- List Group ----------------- */
.list-group {
    padding-right: 0;
}

/* ---------------- List Inline -------------------- */
.list-inline {
    padding-right: 0;
}

.list-inline-item {
    margin-left: .5rem;
    margin-right: auto !important;
}

/* ------------------ Input Group --------------- */

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child),
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child),
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .2rem;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.input-group > .custom-select:not(:first-child),
.input-group > .form-control:not(:first-child) {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.input-group > .custom-select:not(:last-child),
.input-group > .form-control:not(:last-child) {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.input-group-prepend {
    margin-right: 0;
    margin-left: -1px;
}

.input-group-append {
    margin-left: 0;
    margin-right: -1px;
}

.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .btn,
.input-group-append .input-group-text + .input-group-text,
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-prepend .input-group-text + .input-group-text {
    margin-left: 0;
    margin-right: -1px;
}

.input-group > .custom-file + .custom-file,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .form-control,
.input-group > .custom-select + .custom-file,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .form-control,
.input-group > .form-control + .custom-file,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .form-control {
    margin-left: 0;
    margin-right: -1px;
}

.input-group > .custom-file:not(:first-child) .custom-file-label,
.input-group > .custom-file:not(:first-child) .custom-file-label::before {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::before {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.custom-file-label::after {
    left: 0;
    right: auto;
    border-right: 1px solid #ced4da;
    border-radius: .25rem 0 0 .25rem;
}

.custom-control, .form-check {
    padding-left: 0;
    padding-right: 1.25rem;
}

.custom-control-label::before,
.custom-control-label::after {
    left: auto;
    right: 0;
}

.form-check-input {
    margin-left: 0;
    margin-right: -1.25rem;
}

.form-check-inline .form-check-input {
    margin-left: .3125rem;
    margin-right: 0;
}

/* ------------------ Btn Group ------------------ */

.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn:not(:first-child) {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .btn-group {
    margin-right: -1px;
    margin-left: 0;
}

/* ----------------- Drop Down ---------------- */
.dropdown-toggle::after {
    margin-right: .255em;
    margin-left: 0
}

.dropright {
    direction: ltr;
}

.dropright > .btn:not(:last-child):not(.dropdown-toggle) {
    border-radius: .25rem 0 0 .25rem !important;
}

.dropright > .btn:not(:first-child) {
    border-radius: 0 .25rem .25rem 0 !important;
}

/* ------------------- Pagination ------------------------ */
.page-item:first-child .page-link {
    margin-right: 0;
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.page-item:last-child .page-link {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
}

/* ------------------- Dismissing ------------------------ */
.alert-dismissible {
    padding-right: 1.25rem !important;
    padding-left: 4rem;
}

.alert-dismissible .close {
    left: 0;
    right: auto !important;
}

/* ------------------- Offsets ------------------------ */
.offset-1 {
    margin-right: 8.333333%;
    margin-left: unset;
}

.offset-2 {
    margin-right: 16.666667%;
    margin-left: unset;
}

.offset-3 {
    margin-right: 25%;
    margin-left: unset;
}

.offset-4 {
    margin-right: 33.333333%;
    margin-left: unset;
}

.offset-5 {
    margin-right: 41.666667%;
    margin-left: unset;
}

.offset-6 {
    margin-right: 50%;
    margin-left: unset;
}

.offset-7 {
    margin-right: 58.333333%;
    margin-left: unset;
}

.offset-8 {
    margin-right: 66.666667%;
    margin-left: unset;
}

.offset-9 {
    margin-right: 75%;
    margin-left: unset;
}

.offset-10 {
    margin-right: 83.333333%;
    margin-left: unset;
}

.offset-11 {
    margin-right: 91.666667%;
    margin-left: unset;
}

@media (min-width: 576px) {
    .offset-sm-0 {
        margin-right: 0;
        margin-left: unset;
    }

    .offset-sm-1 {
        margin-right: 8.333333%;
        margin-left: unset;
    }

    .offset-sm-2 {
        margin-right: 16.666667%;
        margin-left: unset;
    }

    .offset-sm-3 {
        margin-right: 25%;
        margin-left: unset;
    }

    .offset-sm-4 {
        margin-right: 33.333333%;
        margin-left: unset;
    }

    .offset-sm-5 {
        margin-right: 41.666667%;
        margin-left: unset;
    }

    .offset-sm-6 {
        margin-right: 50%;
        margin-left: unset;
    }

    .offset-sm-7 {
        margin-right: 58.333333%;
        margin-left: unset;
    }

    .offset-sm-8 {
        margin-right: 66.666667%;
        margin-left: unset;
    }

    .offset-sm-9 {
        margin-right: 75%;
        margin-left: unset;
    }

    .offset-sm-10 {
        margin-right: 83.333333%;
        margin-left: unset;
    }

    .offset-sm-11 {
        margin-right: 91.666667%;
        margin-left: unset;
    }
}

@media (min-width: 768px) {
    .offset-md-0 {
        margin-right: 0;
        margin-left: unset;
    }

    .offset-md-1 {
        margin-right: 8.333333%;
        margin-left: unset;
    }

    .offset-md-2 {
        margin-right: 16.666667%;
        margin-left: unset;
    }

    .offset-md-3 {
        margin-right: 25%;
        margin-left: unset;
    }

    .offset-md-4 {
        margin-right: 33.333333%;
        margin-left: unset;
    }

    .offset-md-5 {
        margin-right: 41.666667%;
        margin-left: unset;
    }

    .offset-md-6 {
        margin-right: 50%;
        margin-left: unset;
    }

    .offset-md-7 {
        margin-right: 58.333333%;
        margin-left: unset;
    }

    .offset-md-8 {
        margin-right: 66.666667%;
        margin-left: unset;
    }

    .offset-md-9 {
        margin-right: 75%;
        margin-left: unset;
    }

    .offset-md-10 {
        margin-right: 83.333333%;
        margin-left: unset;
    }

    .offset-md-11 {
        margin-right: 91.666667%;
        margin-left: unset;
    }
}

@media (min-width: 992px) {
    .offset-lg-0 {
        margin-right: 0;
        margin-left: unset;
    }

    .offset-lg-1 {
        margin-right: 8.333333%;
        margin-left: unset;
    }

    .offset-lg-2 {
        margin-right: 16.666667%;
        margin-left: unset;
    }

    .offset-lg-3 {
        margin-right: 25%;
        margin-left: unset;
    }

    .offset-lg-4 {
        margin-right: 33.333333%;
        margin-left: unset;
    }

    .offset-lg-5 {
        margin-right: 41.666667%;
        margin-left: unset;
    }

    .offset-lg-6 {
        margin-right: 50%;
        margin-left: unset;
    }

    .offset-lg-7 {
        margin-right: 58.333333%;
        margin-left: unset;
    }

    .offset-lg-8 {
        margin-right: 66.666667%;
        margin-left: unset;
    }

    .offset-lg-9 {
        margin-right: 75%;
        margin-left: unset;
    }

    .offset-lg-10 {
        margin-right: 83.333333%;
        margin-left: unset;
    }

    .offset-lg-11 {
        margin-right: 91.666667%;
        margin-left: unset;
    }
}

@media (min-width: 1200px) {
    .offset-xl-0 {
        margin-right: 0;
        margin-left: unset;
    }

    .offset-xl-1 {
        margin-right: 8.333333%;
        margin-left: unset;
    }

    .offset-xl-2 {
        margin-right: 16.666667%;
        margin-left: unset;
    }

    .offset-xl-3 {
        margin-right: 25%;
        margin-left: unset;
    }

    .offset-xl-4 {
        margin-right: 33.333333%;
        margin-left: unset;
    }

    .offset-xl-5 {
        margin-right: 41.666667%;
        margin-left: unset;
    }

    .offset-xl-6 {
        margin-right: 50%;
        margin-left: unset;
    }

    .offset-xl-7 {
        margin-right: 58.333333%;
        margin-left: unset;
    }

    .offset-xl-8 {
        margin-right: 66.666667%;
        margin-left: unset;
    }

    .offset-xl-9 {
        margin-right: 75%;
        margin-left: unset;
    }

    .offset-xl-10 {
        margin-right: 83.333333%;
        margin-left: unset;
    }

    .offset-xl-11 {
        margin-right: 91.666667%;
        margin-left: unset;
    }
}

.table-striped tr td:first-of-type {
    border-radius: 0 5px 5px 0;
}

.table-striped tr td:last-of-type {
    border-radius: 5px 0 0 5px;
}
